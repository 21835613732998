import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import '../styles/Ticker.scss'

const query = graphql`
  query {
    allStrapiTickers(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          title
          order
        }
      }
    }
  }
`
const Ticker = () => {
  const { allStrapiTickers } = useStaticQuery(query)
  return (
    <div className="ticker" id="ticker">
      <div className="ticker__container">
        {allStrapiTickers.edges.map(({ node: { title } }, index) => (
          <div className="ticker__item" key={index}>
            {title}
          </div>
        ))}
        {allStrapiTickers.edges.map(({ node: { title } }, index) => (
          <div className="ticker__item" key={`${index}-2`}>
            {title}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Ticker
