import React, { useState } from 'react'
import '../styles/YoutubeVideo.scss'

const YoutubeVideo = ({ youtube, slideWidth, title, source }) => {
  const [isVideo, setIsVideo] = useState(false)
  return (
    <div className="video-youtube">
      {isVideo ? (
        <iframe
          src={`https://www.youtube.com/embed/${youtube}?autoplay=1&`}
          loading="lazy"
          autoPlay
          frameborder="0"
          allowfullscreen="1"
          allow="autoplay; accelerometer; encrypted-media; gyroscope; picture-in-picture"
          style={{ height: `${(slideWidth / 16) * 9}px` }}
        />
      ) : (
        <>
          <h5 className="video-youtube__title">{title}</h5>
          <span
            className="video-youtube__link"
          // href={`https://youtu.be/${youtube}`}
          >
            <picture>
              <source
                srcset={
                  source && source.publicURL
                    ? source.publicURL
                    : `https://i.ytimg.com/vi_webp/${youtube}/maxresdefault.webp`
                }
                type="image/webp"
              />
              <img
                className="video-youtube__media"
                src={
                  source && source.publicURL
                    ? source.publicURL
                    : `https://i.ytimg.com/vi/${youtube}/maxresdefault.jpg`
                }
              />
            </picture>
          </span>
          <button
            onClick={() => {
              setIsVideo(true)
            }}
            className="video-youtube__button"
            aria-label="Запустить видео"
          ></button>
        </>
      )}
    </div>
  )
}

export default YoutubeVideo
