import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import store from '../stores/store'

const query = graphql`
  query {
    allStrapiServices(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          title
          order
          videos {
            link
          }
        }
      }
    }
  }
`
const ServicesMobile = () => {
  const { allStrapiServices } = useStaticQuery(query)
  const services = allStrapiServices.edges.map(
    ({ node: { title, icon, videos } }) => ({
      title,
      videos,
    })
  )
  return (
    <div className="services-mobile">
      <h2 className="services-mobile__title">services</h2>
      {services.map(
        ({ title }, index) =>
          index !== 0 && (
            <button
              onClick={() => {
                store.setCurrentVideo(index)
                store.togglePopup()
              }}
              value={index}
              class="services-mobile__item"
            >
              <div class="circle" />
              <span>{title}</span>
            </button>
          )
      )}
    </div>
  )
}

export default ServicesMobile
