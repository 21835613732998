import React from 'react'
import SmoothScrolling from './SmoothScrolling'
import '../styles/Anchors.scss'

const Anchors = ({ isInverted }) => {
  function scrollToSmoothly(id, time) {
    let pos = document.getElementById(id).offsetTop - document.getElementById('ticker').clientHeight + 0.5
    pos = pos < 0 ? 0 : pos
    if (typeof pos !== "number") {
      pos = parseFloat(pos);
    }
    if (isNaN(pos)) {
      console.warn("Position must be a number or a numeric String.");
      throw "Position must be a number";
    }
    if (pos < 0 || time < 0) {
      return;
    }
    let currentPos = window.scrollY || window.screenTop;
    let start = null;
    time = time || 500;
    window.requestAnimationFrame(function step(currentTime) {
      start = !start ? currentTime : start;
      if (currentPos < pos) {
        let progress = currentTime - start;
        window.scrollTo(0, ((pos - currentPos) * progress / time) + currentPos);
        if (progress < time) {
          window.requestAnimationFrame(step);
        } else {
          window.scrollTo(0, pos);
        }
      } else {
        let progress = currentTime - start;
        window.scrollTo(0, currentPos - ((currentPos - pos) * progress / time));
        if (progress < time) {
          window.requestAnimationFrame(step);
        } else {
          window.scrollTo(0, pos);
        }
      }
    });
  }
  return (
    <div className={`anchors__container`}>
      <div className={`anchors${isInverted ? ' inverted' : ''}`}>
        {[1, 2, 3, 4, 5, 6].map((num) => (
          <a
            className="anchors_item"
            onClick={() => scrollToSmoothly(`${num}`, 500)}
          // href={`#${num}`}
          >
            {num !== 5 && num !== 6 && <span className="anchors_item__value">0{num}</span>}
            {/*{num === 5 && <div className='anchors_item__icon contactUs_icon' />}*/}
            {num === 5 && <div className='anchors_item__icon contacts_icon' />}
          </a>
        ))}
      </div>
    </div>
  )
}

export default Anchors
