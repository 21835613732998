import { navigate } from 'gatsby-link'
import React, { useEffect } from 'react'

import SEO from '../components/seo'

const NotFoundPage = () => {
  useEffect(() => {
    typeof window !== undefined && navigate('/blog')
  }, [])
  return (
    <>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn't exist... the sadness.</p>
    </>
  )
}

export default NotFoundPage
