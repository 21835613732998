import React, { useEffect, useState } from 'react'
import '../styles/ContactUs.scss'
import axios from 'axios'

const ContactUs = () => {
  const [notification, setNotification] = useState()
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [comment, setComment] = useState('')

  useEffect(() => {
    if (notification) {
      if (!notification?.error) {
        setName('')
        setEmail('')
        setComment('')
        setTimeout(() => {
          closeNotification()
        }, 6000)
      }
    }
  }, [notification])

  const closeNotification = () => {
    setNotification(null)
  }

  const send = async () => {
    try {
      const data = {
        Name: name,
        Email: email,
        Comment: comment,
      }

      let formBody = []

      for (const property in data) {
        const encodedKey = encodeURIComponent(property)
        const encodedValue = encodeURIComponent(data[property])
        formBody.push(encodedKey + '=' + encodedValue)
      }

      formBody = formBody.join('&')

      await fetch('https://send.pageclip.co/cP0JDtEUtgG9RbdHM5m8q3xNCiVLVB9P', {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: formBody,
      }).then(() => {
        setNotification({
          message: "Thank you! We'll get back to you very soon!",
          error: false,
        })
      })
    } catch (e) {
      setNotification({
        message: 'Error! Please, try again later',
        error: true,
      })
      console.log(e)
    }
    return false
  }
  return (
    <section className="contactUs" id={6}>
      <h1>Contact Us</h1>
      <form
        className="contactUs__form"
        onSubmit={(e) => {
          e.preventDefault()
          send()
        }}
      >
        {notification && (
          <div className={'contactUs_notification'}>
            <div
              className={`contactUs_notification__icon ${notification.error ? ' _error' : ''}`}
            />
            <div className={'contactUs_notification__close'} onClick={closeNotification}/>
            {notification.message}
          </div>
        )}
        <input
          name="name"
          placeholder="Name"
          type="text"
          id="name"
          required
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          name="email"
          placeholder="Email"
          type="email"
          id="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <textarea
          name="comment"
          id="comment"
          rows="3"
          placeholder="Comment"
          required
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <input type="submit" value="Send" />
      </form>
    </section>
  )
}

export default ContactUs
