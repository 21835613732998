import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

const query = graphql`
  query {
    strapiMeta {
      youtube
      facebook
      vimeo
      instagram
    }
  }
`

const Socials = ({ isInverted }) => {
  const {
    strapiMeta: { facebook, instagram, vimeo, youtube },
  } = useStaticQuery(query)
  return (
    <div className={`socials__container`}>
      <div className={`socials${isInverted ? ' inverted' : ''}`}>
        <a href={facebook} className="socials_item" target='_blank'>
          <div className="socials_item__icon facebook"></div>
        </a>
        <a href={instagram} className="socials_item" target='_blank'>
          <div className="socials_item__icon instagram"></div>
        </a>
        <a href={vimeo} className="socials_item" target='_blank'>
          <div className="socials_item__icon vimeo"></div>
        </a>
        <a href={youtube} className="socials_item" target='_blank'>
          <div className="socials_item__icon youtube"></div>
        </a>
      </div>
    </div>
  )
}

export default Socials
