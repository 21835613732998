import React from 'react'
import Slider from 'react-slick'
import Anchors from './Anchors'
import Socials from './Socials'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import '../styles/Portfolio.scss'
import { useState, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import YoutubeVideo from './YoutubeVideo'

const query = graphql`
  query {
    allStrapiVideos(
      filter: { type: { eq: "portfolio" } }
      sort: { fields: [order], order: ASC }
    ) {
      edges {
        node {
          title
          youtube
          link
          order
          source {
            publicURL
          }
        }
      }
    }
  }
`

function getWindowDimensions() {
  if (typeof window === undefined) return false
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

function NextArrow(props) {
  const { className, style, onClick } = props
  return <div className={className} style={{ ...style }} onClick={onClick} />
}

function PrevArrow(props) {
  const { className, style, onClick } = props
  return <div className={className} style={{ ...style }} onClick={onClick} />
}

const Portfolio = () => {
  // const [isRendered, setIsRendered] = useState(false)
  const [windowDimensions, setWindowDimensions] = useState({
    width: 0,
    height: 0,
  })

  const { allStrapiVideos } = useStaticQuery(query)

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    // setTimeout(() => {
    //   setIsRendered(true)
    // }, 1000)
    // setIsRendered(true)
    handleResize()

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    className: 'portfolio_slider',
    slidesToShow: 3,
    initialSlide: 0,
    adaptiveHeight: true,
    centerPadding: '100px',
    centerMode: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1921,
        settings: {
          initialSlide: allStrapiVideos.edges.length - 1,
        },
      },
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 2,
          initialSlide: 0,
          dots: false,
          centerPadding: '100px',
        },
      },
      {
        breakpoint: 901,
        settings: {
          slidesToShow: 1,
          initialSlide: 1,
          centerPadding: '0px',
          dots: true,
          customPaging: (i) => (
            <div
              className="portfolio__dot"
              id={`portfolio__dot-${i + 1}`}
            ></div>
          ),
        },
      },
    ],
  }
  const slideWidth =
    windowDimensions.width >= 1200
      ? windowDimensions.width / 3 - 100
      : windowDimensions.width > 900
      ? windowDimensions.width / 2 - 100
      : windowDimensions.width
  return (
    <div className="section portfolio" id="3">
      <Anchors isInverted />
      <Socials isInverted />
      <h3 className="portfolio__title">Portfolio</h3>
      <hr />
      <Slider {...settings}>
        {allStrapiVideos.edges.map(({ node: { youtube, title, source } }) => (
          <div className="portfolio__slide">
            {
              <YoutubeVideo
                youtube={youtube}
                title={title}
                source={source}
                slideWidth={slideWidth}
              />
            }
          </div>
        ))}
      </Slider>
      <div className="portfolio__overlay">
        <span>
          po
          <br />
          rtf
          <br />
          olio
        </span>
      </div>
    </div>
  )
}

export default Portfolio
