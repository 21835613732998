import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import loadable from '@loadable/component'
import store from '../stores/store'
import SEO from '../components/seo'
import Ticker from '../components/Ticker'
import Intro from '../components/Intro'
import Circular from '../components/Circular'
import css from '../styles/Global.scss'
import ServicesMobile from '../components/ServicesMobile'
import Portfolio from '../components/Portfolio'
import Reviews from '../components/Reviews'
import Popup from '../components/Popup'
import Preheader from '../components/Preheader'
import Footer from '../components/Footer'
import Stars from '../components/Stars'
import Preloader from '../components/Preloader'
import ContactUs from "../components/ContactUs";

// const Portfolio = loadable(() => import('../components/Portfolio'))

export const query = graphql`
  query {
    strapiMeta {
      backgroundVideo {
        publicURL
      }
      facebook
      youtube
      vimeo
      instagram
      phone
      address
      email
    }
  }
`

function getWindowDimensions() {
  if (typeof window === undefined) return false
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

const IndexPage = ({ data }) => {
  const { strapiMeta } = data
  const [windowDimensions, setWindowDimensions] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
      let vh = window.innerHeight / 100;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    handleResize()

    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    }
    window.addEventListener('resize', handleResize)
    // for (const section of document.getElementsByClassName('section')) {
    //   section.style.minHeight = `${windowDimensions.height - document.getElementById('ticker').clientHeight}px`
    // }
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <>
      <SEO {...strapiMeta} />
      <Preloader />
      <Intro {...strapiMeta} />
      <Preheader {...strapiMeta} />
      <Ticker />
      <Circular />
      <ServicesMobile />
      <Portfolio />
      <Reviews />
      {/*<ContactUs />*/}
      <Footer {...strapiMeta} />
      <Popup />
      <Stars />
      <script src="https://s.pageclip.co/v1/pageclip.js" charSet="utf-8"/>
    </>
  )
}

export default observer(IndexPage)
