import React from 'react'
import '../styles/Preheader.scss'
import { graphql, useStaticQuery } from 'gatsby'

const Preheader = ({ phone, email }) => {
  return (
    <div class="preheader">
      <div class="preheader-item">
        <div class="icon icon-phone"></div>
        <a href={`tel:${phone.replace(/\D/g, '')}`}>{phone}</a>
      </div>
      <div class="preheader-item">
        <div class="icon icon-email"></div>
        <a href={`mailto:${email}`}>{email}</a>
      </div>
    </div>
  )
}

export default Preheader
