import React from 'react'
import Slider from 'react-slick'
import Anchors from './Anchors'
import Socials from './Socials'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import '../styles/Reviews.scss'
import Comment from './Comment'
import { graphql, useStaticQuery } from 'gatsby'

const query = graphql`
  query {
    allStrapiReviews(sort: { fields: [Order], order: ASC }) {
      edges {
        node {
          Name
          Role
          Date
          Text
          WorkType
          Price
          Order
          Avatar {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
const Reviews = () => {
  const { allStrapiReviews } = useStaticQuery(query)
  const settings = {
    infinite: true,
    speed: 500,
    className: 'reviews_slider',
    slidesToShow: 3,
    initialSlide: 0,
    dots: true,
    centerPadding: '0',
    centerMode: true,
    responsive: [
      {
        breakpoint: 1921,
        settings: {
          initialSlide: allStrapiReviews.edges.length - 1,
        },
      },
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 2,
          initialSlide: 0,
          centerPadding: '0',
        },
      },
      {
        breakpoint: 901,
        settings: {
          slidesToShow: 1,
          initialSlide: 1,
          centerPadding: '0',
        },
      },
    ],
    customPaging: (i) => (
      <div className="reviews__dot" id={`reviews__dot-${i + 1}`}></div>
    ),
  }

  return (
    <div className="section reviews" id="4">
      <Anchors isInverted={false} />
      <Socials isInverted={false} />
      <h3 className="reviews__title">Reviews</h3>
      <hr />
      <Slider {...settings}>
        {allStrapiReviews.edges.map(
          (
            { node: { Name, Role, Date, Text, WorkType, Price, Avatar } },
            index
          ) => (
            <Comment
              name={Name}
              role={Role}
              date={Date}
              text={Text}
              workType={WorkType}
              price={Price}
              index={index}
              avatar={Avatar}
            />
          )
        )}
      </Slider>
    </div>
  )
}

export default Reviews
