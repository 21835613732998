import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

const query = graphql`
  query {
    strapiMeta {
      keywords
      description
      title
      image {
        publicURL
      }
      favicon {
        publicURL
      }
    }
  }
`

const SEO = () => {
  const { strapiMeta } = useStaticQuery(query)

  // Merge default and page-specific SEO values
  const fullSeo = { ...strapiMeta }

  const getMetaTags = () => {
    const tags = []

    if (fullSeo.title) {
      tags.push(
        {
          property: 'og:title',
          content: fullSeo.title,
        },
        {
          name: 'twitter:title',
          content: fullSeo.title,
        }
      )
    }
    if (fullSeo.description) {
      tags.push(
        {
          name: 'description',
          content: fullSeo.description,
        },
        {
          property: 'og:description',
          content: fullSeo.description,
        },
        {
          name: 'twitter:description',
          content: fullSeo.description,
        }
      )
    }
    if (fullSeo.image) {
      const imageUrl = 'https://50starscinema.com' + fullSeo.image.publicURL
      tags.push(
        {
          name: 'image',
          content: imageUrl,
        },
        {
          property: 'og:image',
          content: imageUrl,
        },
        {
          name: 'twitter:image',
          content: imageUrl,
        }
      )
    }
    if (fullSeo.article) {
      tags.push({
        property: 'og:type',
        content: 'article',
      })
    }
    tags.push({ name: 'twitter:card', content: 'summary_large_image' })

    return tags
  }

  const metaTags = getMetaTags()
  console.log(`metaTags`, metaTags)
  return (
    <Helmet
      title={fullSeo.title}
      keywords={fullSeo.keywords}
      link={[
        {
          rel: 'icon',
          href: fullSeo.favicon && fullSeo.favicon.publicURL,
        },
      ]}
      meta={metaTags}
    >
      <meta name="description" content={fullSeo.description} />
      <meta name="image" content={fullSeo.image} />
    </Helmet>
  )
}

export default SEO

// SEO.propTypes = {
//   title: PropTypes.string,
//   description: PropTypes.string,
//   image: PropTypes.string,
//   article: PropTypes.bool,
// }

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}
