import React from 'react'
import Anchors from './Anchors'
import Socials from './Socials'
import Icon from './Icon'
import '../styles/Services.scss'
import { graphql, useStaticQuery } from 'gatsby'
import store from '../stores/store'

const query = graphql`
  query {
    allStrapiServices(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          title
          order
          icon
          videos {
            link
          }
        }
      }
    }
  }
`
const Circular = () => {
  const { allStrapiServices } = useStaticQuery(query)
  const services = allStrapiServices.edges.map(
    ({ node: { title, icon, videos } }) => ({
      title,
      icon,
      videos,
    })
  )
  store.setServices(services)
  const offsets = [
    [110, -119, -301, 450],
    [173.5, -273, -30, 350],
    [240, -164, 288, 465],
    [294, 137, 282, 430],
    [-7, 280, 26, 361],
    [61, 169, -310, 505],
  ]
  return (
    <div className="section services" id="2">
      <Anchors isInverted={false} />
      <Socials isInverted={false} />
      <menu>
        <button
          value={0}
          className="services__item"
          id="center"
          onClick={() => {
            store.setCurrentVideo(0)
            store.togglePopup()
          }}
          style={{ top: '50%', left: '50%' }}
        >
          <img src="/img/logo-white.png" alt="50starscinema logo" />
          <div className="glowing"></div>
        </button>
        {services.map(
          ({ title, icon }, index) =>
            index !== 0 && (
              <>
                <div
                  className="services__line"
                  style={{
                    transform: `translate(-50%,-50%) rotate(${offsets[index - 1][0]
                      }deg)`,
                    height: `${offsets[index - 1][3]}px`,
                  }}
                ></div>
                <button
                  value={index}
                  onClick={() => {
                    store.setCurrentVideo(index)
                    store.togglePopup()
                  }}
                  className="services__item"
                  style={{
                    top: `calc(${offsets[index - 1][1]}px + 50%)`,
                    left: `calc(${offsets[index - 1][2]}px + 50%)`,
                  }}
                >
                  <Icon icon={icon} />
                  <span>{title}</span>
                  <svg viewBox="0 0 50 50">
                    <circle
                      cx="25"
                      cy="25"
                      r="24.75"
                      fill="none"
                      strokeWidth="0.5"
                    ></circle>
                  </svg>
                </button>
              </>
            )
        )}
      </menu>
    </div>
  )
}

export default Circular
