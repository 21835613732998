import React, { useEffect, useState } from 'react'
import Anchors from './Anchors'
import Socials from './Socials'
import '../styles/Intro.scss'

const Intro = ({ backgroundVideo }) => {
  // const [isRendered, setIsRendered] = useState(false)

  // useEffect(() => {
  //   setIsRendered(true)
  // }, [])

  return (
    <div className={'intro section'} id="1">
      <Anchors isInverted={false} />
      <Socials isInverted={false} />
      {/* {isRendered && ( */}
      <video
        className="video"
        src={backgroundVideo.publicURL}
        muted="muted"
        poster
        type="video/mp4"
        loop
        controlsList="nodownload"
        playsInline
        allowFullScreen
        autoPlay
      />
      {/* )} */}
      <div className="video-cover" />
      <img src="/img/logo-white.png" className="logo" />
    </div>
  )
}

export default Intro
