import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import React from 'react'
import store from '../stores/store'
import '../styles/Popup.scss'

const Popup = () => {
  if (typeof window !== 'undefined')
    store.isPopupVisible ?
      document.body.style.overflow = "hidden" :
      document.body.style.overflow = "overlay"
  return (
    store.isPopupVisible && (
      <div className="popup__container" id="popup">
        <button
          className="popup__button popup__close"
          onClick={() => {
            store.togglePopup()
          }}
        >
          <div />
          Close
        </button>
        <button
          className="popup__button popup__prev popup__arrow"
          onClick={() => {
            store.setCurrentVideo(store.currentVideo - 1)
          }}
        />
        <div className="popup__bg" onClick={() => { store.togglePopup() }}/>
        <div className={`popup_video__container${store.services[store.currentVideo].videos.length === 2 ? ' _double' : ''}`}>
          {store.services[store.currentVideo].title && <p className='popup_video__title' key={store.services[store.currentVideo].title}>{store.services[store.currentVideo].title}</p>}
          {store.services[store.currentVideo].videos.map(({ link }, index) => (
            index === 0 ? <video poster type="video/mp4" loop controls="controls" playsInline allowFullScreen autoPlay className="popup_video" src={link} /> :
              <video poster type="video/mp4" loop controls="controls" playsInline allowFullScreen className="popup_video" src={link} />
          ))}
        </div>
        <button
          className="popup__button popup__next popup__arrow"
          onClick={() => {
            store.setCurrentVideo(store.currentVideo + 1)
          }}
        />

        <main>{store.currentVideo}</main>
        {console.log(toJS(store.services))}
      </div>
    )
  )
}

export default observer(Popup)
