import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react'
import '../styles/Preloader.scss'

const Preloader = () => {
  const [isVisible, setIsVisible] = useState(true)
  useEffect(() => {
    const timerId = setTimeout(() => {
      setIsVisible(false)
    }, 5000);
    document.body.style.overflowY = 'overlay'
    return () => {
      clearTimeout(timerId);
    }
  }, [])
  return (
    <div className={`preloader${!isVisible ? ' loaded' : ''}`}>
      <video
        src='/video/preloader.mp4'
        muted="muted"
        poster
        type="video/mp4"
        controlsList="nodownload"
        playsInline
        allowFullScreen
        autoPlay
      />
    </div>
  );
}

export default observer(Preloader);
