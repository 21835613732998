import React from 'react'
import Anchors from './Anchors'
import Socials from './Socials'
import '../styles/Footer.scss'
import ContactUs from "./ContactUs";

const Footer = ({
  facebook,
  youtube,
  vimeo,
  instagram,
  phone,
  address,
  email,
}) => (
  <div className="section contacts" id="5">
    <Anchors isInverted={true} />
    <Socials isInverted={true} />
    <div className="contacts-container">
      <video className="logo-section" muted autoPlay playsInline loop src={'/video/logo-black.mp4'} />
      <ContactUs/>
      <div className="contacts-data">
        {/*<div className="contacts-item call">*/}
        {/*  <div className="heading">Call</div>*/}
        {/*  <div className="data">*/}
        {/*    <a href={`tel:${phone.replace(/\D/g, '')}`}>{phone}</a>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="contacts-item call">
          <div className="heading">Email</div>
          <div className="data">
            <a href={`mailto:${email}`}>{email}</a>
          </div>
        </div>
        {/*<div className="contacts-item call">*/}
        {/*  <div className="heading">Come</div>*/}
        {/*  <div className="data">{address}</div>*/}
        {/*</div>*/}
      </div>
      <div className="social-section">
        <ul>
          <li className="list-inline-item">
            <a href={facebook} target="_blank" className="socials_item">
              <div className="socials_item__icon facebook"></div>
            </a>
          </li>
          <li className="list-inline-item">
            <a href={instagram} target="_blank" className="socials_item">
              <div className="socials_item__icon instagram"></div>
            </a>
          </li>
          <li className="list-inline-item">
            <a href={vimeo} target="_blank" className="socials_item">
              <div className="socials_item__icon vimeo"></div>
            </a>
          </li>
          <li className="list-inline-item">
            <a href={youtube} target="_blank" className="socials_item">
              <div className="socials_item__icon youtube"></div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
)

export default Footer
