import GatsbyImage from 'gatsby-image'
import React, { useEffect, useState } from 'react'

const Comment = ({
  name,
  role,
  date,
  text,
  workType,
  price,
  avatar,
  index,
}) => {
  const [isOpened, setIsOpened] = useState(false)
  const [textHeight, setTextHeight] = useState(0)

  useEffect(() => {
    setTextHeight(
      document &&
        document.getElementById(`review__text-${index}`) &&
        document.getElementById(`review__text-${index}`).clientHeight
    )
  }, [])

  Number.prototype.format = function (n, x) {
    let re = '(\\d)(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')'
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$1.')
  }

  function timeSince(date) {
    let seconds = Math.floor((new Date() - date) / 1000)

    let interval = seconds / 31536000

    if (Math.floor(interval) === 1) {
      return Math.floor(interval) + ' year'
    }
    if (interval > 1) {
      return Math.floor(interval) + ' years'
    }
    interval = seconds / 2592000
    if (Math.floor(interval) === 1) {
      return Math.floor(interval) + ' month'
    }
    if (interval > 1) {
      return Math.floor(interval) + ' months'
    }
    interval = seconds / 86400
    if (Math.floor(interval) === 1) {
      return Math.floor(interval) + ' day'
    }
    if (interval > 1) {
      return Math.floor(interval) + ' days'
    }
    interval = seconds / 3600
    if (Math.floor(interval) === 1) {
      return Math.floor(interval) + ' hour'
    }
    if (interval > 1) {
      return Math.floor(interval) + ' hours'
    }
    interval = seconds / 60
    if (Math.floor(interval) === 1) {
      return Math.floor(interval) + ' minute'
    }
    if (interval > 1) {
      return Math.floor(interval) + ' minutes'
    }
    if (Math.floor(seconds) === 1) return Math.floor(seconds) + ' second'
    else return Math.floor(seconds) + ' seconds'
  }
  return (
    <div>
      <div className="review">
        {avatar ? (
          <GatsbyImage
            fluid={avatar.childImageSharp.fluid}
            className="review__avatar"
          />
        ) : (
          <img
            src="https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"
            alt={`${name}'s avatar`}
            className="review__avatar"
          />
        )}

        <span className="review__name">{name}</span>
        <div className="review__info">
          <span>{role}</span>
          <div className="review__divider" />
          <span>{timeSince(new Date(Date.parse(date)))} ago</span>
        </div>
        <p className="review__text _hidden" id={`review__text-${index}`}>
          {text}
        </p>
        <p className={`review__text${isOpened ? ' _more' : ''}`}>{text}</p>
        {textHeight > 260 && (
          <button
            className="review__more"
            onClick={() => setIsOpened(!isOpened)}
          >
            {!isOpened ? 'Show more' : 'Show less'}
          </button>
        )}

        <div className="review__info">
          <span>{workType}</span>
          <div className="review__divider" />
          <span>Budget: ${price.format(0)}</span>
        </div>
      </div>
    </div>
  )
}

export default Comment
