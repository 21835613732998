import { makeAutoObservable } from 'mobx'

const createStore = () => ({
  isPopupVisible: false,
  services: [],
  currentVideo: 0,

  setServices(services) {
    this.services = services
  },

  setCurrentVideo(index) {
    if (index > this.services.length - 1) {
      this.currentVideo = 0
    } else if (index < 0) {
      this.currentVideo = this.services.length - 1
    } else {
      this.currentVideo = index
    }
  },

  togglePopup() {
    this.isPopupVisible = !this.isPopupVisible
  },
})

export default makeAutoObservable(createStore())
