import React from 'react';
import '../styles/Icon.scss'

const Icon = ({ icon }) => {
  return (
    <div>
      <div class={`animated-icon ${icon}`}>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>
    </div>
  );
}

export default Icon;
